html,
body,
#root {
  height: 100%;
  font-family: 'Open Sans';
  color: #7f8283;
}

html {
  scroll-behavior: smooth;
}

a {
  color: #8d1e1f;
}

a:hover {
  color: #ba0d1c;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.i4ewOd-pzNkMb-haAclf {
  display: none !important;
}


.i4ewOd-pzNkMb-tJHJj {
  display: none !important;
}
